<template>
  <div class="d-flex flex-column align-items-center col-12">
    <div class="navbar-container">
      <div v-if="windowWidth >= 500">
        <DesktopNavBar/>
      </div>
      <div v-if="windowWidth < 500">
        <MobileNavBar/>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center dashboard-container col-12">
      <button @click="logoutClicked()" v-if="windowWidth >= 500" class="logout-button">Logout</button>
      <div class="header-text">Let's view your Leave Status!</div>
      <div class="table-header mt-4">Leave Application Status</div>
      <div class="mt-2">Total Annual Leave Remaining: {{ userAnnualLeaveDaysRemaining }}</div>
      <div class="d-flex flex-column align-items-center justify-content-center col-11 mt-4 mb-5">
        <table class="leave-table col-12 col-xl-7">
          <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Duration</th>
            <th scope="col">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(leave) in userLeaveTable" :key="leave.id">
            <td>{{ leave.type }}</td>
            <td>{{ leave.startDate }}</td>
            <td>{{ leave.endDate }}</td>
            <td>{{ leave.duration }}</td>
            <td :class="textColourChoice(leave.status)">{{ leave.status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MobileNavBar from "@/views/components/mobile/MobileNavBar";
import DesktopNavBar from "@/views/components/desktop/DesktopNavBar";
import {ref} from 'vue'
import {mapMutations, mapState} from "vuex";
import DashboardService from "@/services/dashboardService";
import moment from 'moment';

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: "LeaveStatus",
  components: {MobileNavBar, DesktopNavBar},
  computed: {
    ...mapState(['userData']),
  },
  data() {
    return {
      windowWidth: windowWidth,
      userLeaveTable: [],
      userAnnualLeaveDaysRemaining: 'TBC',
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading']),
    async getUserLeaveStatus() {
      const response = await DashboardService.getAllEmployeeRequestedLeave();
      this.userLeaveTable = this.filterUserLeave(response);
      this.userLeaveTable.reverse()
    },
    filterUserLeave(allCompanyLeave) {
      return allCompanyLeave
          .filter(({Surname}) => Surname === this.userData.Surname)
          .map((leaveItem) => {
            const statusLeaveApplication = leaveItem.Approved === null ? 'Pending' : (leaveItem.Approved ? 'Approved' : 'Denied');

            return {
              type: leaveItem.LeaveType,
              startDate: this.formatDate(leaveItem.StartDate),
              endDate: this.formatDate(leaveItem.EndDate),
              duration: leaveItem.DurationHours * 3 / 24 + ' Day(s)',
              status: statusLeaveApplication
            };
          });
    },
    formatDate(date) {
      return moment(date.toString().slice(0, 10)).format('DD-MM-Y');
    },
    textColourChoice(leaveStatus) {
      const colorMap = {
        'Approved': 'orange-text',
        'Pending': 'default-text',
        'Denied': 'red-text'
      };

      return colorMap[leaveStatus] || '';
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`);
    },
    logoutClicked() {
      this.setUserData(undefined);
      this.routeToPage('/login')
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getUserLeaveStatus();
    this.setIsLoading(false);
  },
  mounted() {
    window.addEventListener('resize', handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  },
}
</script>

<style scoped>
.dashboard-container {
  position: relative;
  top: 0;
  padding-top: 15px;
  height: 92vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  right: 10px;
  top: 10px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-container::-webkit-scrollbar {
  display: none;
}

.header-text {
  font-size: 60px;
  color: #EE5C36;
  line-height: 1.1;
  margin-left: 30px;
}

.orange-text {
  color: #EE5C36;
}

.default-text {
  color: #000000;
}

.red-text {
  color: #FF0303FF;
}

.table-header {
  font-size: 25px;
}

tr {
  height: 40px;
  border-bottom: 1px solid #000000;
}

th {
  font-size: 18px;
  color: #EE5C36;
}

@media screen and (max-width: 500px ) {
  .header-text {
    font-size: 40px;
  }

  .table-header {
    font-size: 20px;
  }

  tr {
    font-size: 10px;
  }

  th {
    font-size: 14px;
    color: #EE5C36;
  }

}
</style>
